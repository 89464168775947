import React, { Component } from 'react';
import { UsersTable } from './components';
import Filter from './components/Filter/Filter'
import { getPeople, submitQueryBuilder, createSegmant } from '../../api/api'
import { connect } from 'react-redux';
import * as actions from '../../store/actions/actions';

class UserList extends Component {

  state = {
    checkedUsers: [],
  }
  componentDidMount() {
    this.getUsers(1);
  }

  getFilteredUsers = (page) => {
    this.props.setUsers([]);
    this.props.setIsUsersLoading(true);
    submitQueryBuilder(page, this.props.query,
      (response) => {
        const data = response.data.data.submitQueryBuilder;
        this.props.setIsUsersLoading(false)
        if (page === 1) {
          this.props.setUsersCount(data.count);
        }
        this.props.setUsers(data.people)
      },
      (error) => {
        console.log(error);
      })
  }

  getUsers = (page) => {
    this.props.setUsers([]);
    this.props.setIsUsersLoading(true);
    getPeople(page,
      (response) => {
        const data = response.data.data.people;
        this.props.setIsUsersLoading(false)
        if (page === 1) {
          this.props.setUsersCount(data.count);
        }
        this.props.setUsers(data.people)
      }, (error) => {
        console.log(error);
      })
  }

  resetUsersHandler = () => {
    this.props.setIsFiltered(false);
    this.props.setPage(0);
    this.getUsers(1);
    this.setState({ checkedUsers: [] });
  }

  pageChangedHandler = (page) => {
    if (this.props.isFiltered) {
      this.getFilteredUsers(page + 1)
    }
    else {
      this.getUsers(page + 1);
    }
  }

  onFilterHandler = () => {
    this.props.setPage(0);
    this.props.setIsFiltered(true);
    this.getFilteredUsers(1);
  }

  saveSegmantHandler = (segmantName) => {
    if (this.props.query) {
      createSegmant(segmantName, this.props.query)
    }
  }

  userCheckedHandler = (checked, id) => {
    let users = this.state.checkedUsers;
    if (checked) {
      users.push(id)
    }
    else {
      users = users.filter((user) => {
        return user !== id;
      })
    }
    this.setState({ checkedUsers: users });
    let query = [
      {
        id: Date.now(),
        group: 'user_data',
        column: '_id',
        operation: 'is_equal',
        value: users
      }
    ]
    this.props.setQuery(query);
  }

  render() {
    return (
      <div className='p-3' >
        <Filter
          onFiltered={this.onFilterHandler}
          onReset={this.resetUsersHandler}
          onSaveSegmant={this.saveSegmantHandler} />
        <div className='mt-2' >
          <UsersTable
            onUserChecked={this.userCheckedHandler}
            onPageChanged={this.pageChangedHandler} />
        </div>
      </div >
    );
  }
};

const mapStateToProps = state => {
  return {
    query: state.queryBuilder.query.map((row) => {
      return {
        ...row,
        id: undefined
      }
    }),
    currentPage: state.queryBuilder.currentPage,
    isFiltered: state.queryBuilder.isFiltered
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setPage: (page) => { dispatch({ type: actions.SET_PAGE, page: page }) },
    setQuery: (query) => { dispatch({ type: actions.SET_QUERY, query: query }) },
    setUsers: (users) => { dispatch({ type: actions.SET_USERS, users: users }) },
    setUsersCount: (count) => { dispatch({ type: actions.SET_USERS_COUNT, count: count }) },
    setIsFiltered: (isFiltered) => { dispatch({ type: actions.SET_IS_FILTERED, isFiltered: isFiltered }) },
    setIsUsersLoading: (isLoading) => { dispatch({ type: actions.SET_IS_USERS_LOADING, isLoading: isLoading }) },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserList);