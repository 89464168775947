import React from 'react';
import classes from './Step.module.css';
import Box from '@material-ui/core/Box';
import RecievedIcon from '@material-ui/icons/Send';
import OpenIcon from '@material-ui/icons/Visibility';
import ClickIcon from '@material-ui/icons/TouchApp';
import OptionsMenu from '../OptionsMenu/OptionsMenu'
import { connect } from 'react-redux'
import * as actions from '../../../../store/actions/actions';

const step = (props) => {

    const optionSelectedHandler = () => {
        let updatedSequences = [...props.sequences];
        let arr = props.id.split('_');
        updatedSequences.map((sequence) => {
            if (sequence._id === arr[0]) {
                sequence.sequence_steps = sequence.sequence_steps.filter((step, index) => {
                    return index !== parseInt(arr[1]);
                });
            }
            return sequence;
        })
        props.setSelectedStep(null);
        props.setSequences(updatedSequences);
    }

    const stepChangedHandler = (key, event) => {
        let updatedSequences = [...props.sequences];
        let arr = props.id.split('_');
        updatedSequences.find((sequence) => {
            return sequence._id === arr[0];
        }).sequence_steps[arr[1]][key] = event.target.value;
        props.setSequences(updatedSequences)
    }

    const stepSelectedHandler = (step) => {
        props.setLoadingMessage(true);
        props.setSelectedStep(step);
        props.setSelectedSequence(step.split('_')[0]);
        setTimeout(() => {
            props.setLoadingMessage(false)
        }, 500);
    }
    return (
        <div className={classes.Step} >
            {props.clicked &&
                <div className={classes.Menu}>
                    send in
                 <div className={classes.Edit}>
                        {props.step.unit !== 'immediately' && props.step.unit !== 'off' &&
                            <input type='number' value={props.step.time_interval} onChange={stepChangedHandler.bind(this, 'time_interval')} />}
                        <select value={props.step.unit} onChange={stepChangedHandler.bind(this, 'unit')}>
                            <option value='immediately'>Immediately</option>
                            <option value='seconds'>Seconds</option>
                            <option value='minutes'>Minutes</option>
                            <option value='days'>Days</option>
                            <option value='hours'>Hours</option>
                            <option value='off'>Off</option>
                        </select>
                    </div>
                </div>
            }
            <Box
                boxShadow={1}
                className={props.selected ? `${classes.Time} ${classes.Selected}` : classes.Time}
                onClick={props.setClickedStep.bind(this, props.id)}
            >
                {props.step.unit === 'immediately' || props.step.unit === 'off' ?
                    props.step.unit :
                    `After ${props.step.time_interval} ${props.step.unit}`
                }
            </Box>
            <Box
                boxShadow={2}
                className={props.selected ? `${classes.Details} ${classes.Selected}` : classes.Details}

            >
                <div className={classes.Analytics} onClick={stepSelectedHandler.bind(this, props.id)}>
                    <div className={classes.Number}>
                        {`#${parseInt(props.id.split('_')[1]) + 1}`}
                    </div>
                        <RecievedIcon fontSize='small'/>
                        <span>0</span>
                        <OpenIcon fontSize='small'/>
                        <span>0</span>
                        <ClickIcon fontSize='small'/>
                        <span>0</span>
                </div>
                <OptionsMenu color={props.selected ? '#fff': ''} options={['Remove']} onSelect={optionSelectedHandler} />
            </Box>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        sequences: state.sequenceBuilder.sequences
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setClickedStep: (step) => { dispatch({ type: actions.SET_CLICKED_STEP, step: step }) },
        setSelectedStep: (step) => { dispatch({ type: actions.SET_SELECTED_STEP, step: step }) },
        setSelectedSequence: (sequence) => { dispatch({ type: actions.SET_SELECTED_SEQUENCE, sequence: sequence }) },
        setLoadingMessage: (isLoading) => { dispatch({ type: actions.SET_IS_LOADING_MESSAGE, isLoading: isLoading }) },
        setSequences: (sequences) => { dispatch({ type: actions.SET_SEQUENCES, sequences: sequences }) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(step);