import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import authReducer from './store/reducers/authReducer'
import queryBuilderReducer from './store/reducers/queryBuilderReducer'
import sequenceBuilderReducer from './store/reducers/sequenceBuilderReducer';
import * as serviceWorker from './serviceWorker';
import App from './App';


let rootReducer = combineReducers({
    auth: authReducer,
    queryBuilder: queryBuilderReducer,
    sequenceBuilder: sequenceBuilderReducer
})

const store = createStore(rootReducer);

const app = (
    <Provider store={store}>
        <App />
    </Provider>
)

ReactDOM.render(app, document.getElementById('root'));

serviceWorker.unregister();
