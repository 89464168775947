import React, { Component } from 'react';
import QueryRow from './QueryRow/QueryRow';
import SaveIcon from '@material-ui/icons/Save';
import Modal from '@material-ui/core/Modal';
import {
    Card,
    CardHeader,
    CardContent,
    Divider,
    Button,
    CardActions,
    TextField
} from '@material-ui/core';
import classes from './Filter.module.css';
import * as actions from '../../../../store/actions/actions';
import { connect } from 'react-redux';

class Filter extends Component {

    state = {
        isModalOpen: false,
        segmantName: '',
    }

    newRowHandler = () => {
        let queryRows = [...this.props.queryRows];
        let queryRow = {
            id: Date.now(),
            group: null,
            column: null,
            operation: null,
            value: [],
            sub_operation: 'or',
        }
        queryRows.push(queryRow);
        this.props.setQuery(queryRows)
    }

    renderQueryRows = () => {
        return this.props.queryRows.map((row, i) => {
            return <QueryRow
                key={row.id}
                row={row}
                onChanged={this.rowChangedHandler.bind(this, row.id)}
                canRemoved={this.props.queryRows.length > 1}
                isLastRow={i === this.props.queryRows.length - 1}
                onRemoved={this.removeRowHandler.bind(this, row.id)} />
        })
    }

    removeRowHandler = (id) => {
        let updatedQueryRows = this.props.queryRows.filter((row) => {
            return row.id !== id;
        })
        this.props.setQuery(updatedQueryRows);
    }


    rowChangedHandler = (id, item, value) => {
        let updatedQueryRows = this.props.queryRows.map((row) => {
            if (row.id === id) {
                row[item] = value;
                if (item === 'group') {
                    row.column = null
                    row.operation = null
                    row.value = []
                    row.sub_operation = null
                }
                else if (item === 'column') {
                    row.operation = null
                    row.value = []
                }
            }
            return row;
        });
        this.props.setQuery(updatedQueryRows)
    }

    filterHandler = () => {
        this.props.onFiltered()
    }

    resetHandler = () => {
        this.props.setQuery([
            {
                id: Date.now(),
                group: null,
                column: null,
                operation: null,
                value: [],
                sub_operation: 'or',
            }]);
        this.props.onReset();
    }

    openModalHandler = () => {
        this.setState({ isModalOpen: true })
    }

    segmantNameChangedHandler = (event) => {
        this.setState({ segmantName: event.target.value });
    }

    saveSegmantHandler = () => {
        if (this.state.segmantName) {
            this.props.onSaveSegmant(this.state.segmantName);
        }
        this.setState({ isModalOpen: false, segmantName: '' });
    }

    render() {
        return (
            <Card>
                <CardHeader title="Filter" />
                <Divider />
                <CardContent>
                    <div className='container-fluid'>
                        <div className='text-right'>
                            <Button onClick={this.newRowHandler} color="primary" variant="contained" className='mx-1'>Add</Button>
                            <Button onClick={this.filterHandler} color="primary" variant="contained" className='mx-1'>Filter</Button>
                            <Button onClick={this.resetHandler} color="secondary" variant="contained" className='mx-1'>Reset</Button>
                        </div>
                        {this.renderQueryRows()}
                    </div>
                </CardContent>
                <Divider />
                <CardActions className='float-right'>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.openModalHandler}
                        startIcon={<SaveIcon />}
                    >Save</Button>

                    <Modal
                        open={this.state.isModalOpen}
                        onClose={() => { this.setState({ isModalOpen: false }) }}
                    >
                        <div className={classes.Modal}>
                            <div>
                                <TextField
                                    label="Segmant name..."
                                    variant="outlined"
                                    className='mt-1'
                                    onChange={this.segmantNameChangedHandler} />
                            </div>
                            <Button
                                variant="outlined"
                                size="small"
                                color="primary"
                                className='mt-3'
                                onClick={this.saveSegmantHandler}>Ok</Button>
                        </div>
                    </Modal>
                </CardActions>
            </Card >
        )
    }

}

const mapStateToProps = state => {
    return {
        queryRows: state.queryBuilder.query
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setQuery: (query) => { dispatch({ type: actions.SET_QUERY, query: query }) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Filter);