import React from 'react';
import Fade from '@material-ui/core/Fade';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import classes from './OptionsMenu.module.css'

function OptionsMenu(props) {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <IconButton className={classes.Options} onClick={handleClick}>
                <MoreVertIcon style={{ color: props.color }} fontSize='small'/>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                {props.options.map((option) => {
                    return <MenuItem key={option} onClick={() => { props.onSelect(option); handleClose() }}>{option}</MenuItem>
                })}

            </Menu>
        </React.Fragment>
    );
}

export default OptionsMenu;