import React from 'react';
import Sequence from '../Sequence/Sequence'
import {
    Card,
    CardHeader,
    CardContent,
    Divider,
} from '@material-ui/core';
import { connect } from 'react-redux';
import classes from './Sequences.module.css'
import Button from '@material-ui/core/Button';
import { createSequence, listSequences } from '../../../../api/api';
import * as actions from '../../../../store/actions/actions';


const sequences = (props) => {

    const addSequenceHandler = () => {
        let sequenceName = `Untitled sequence`;
        let steps = [];
        createSequence(sequenceName, steps, () => {
            listSequences((response) => {
                let sequences = response.data.data.listSequence;
                props.setSequences(sequences);
            }, (error) => {
                console.log(error);
            })
        })
    }

    return (
        <div style={{ overflow: 'auto', maxHeight: '87vh' }}>
            <Card>
                <CardHeader title="Sequences" />
                <Divider />
                <CardContent>
                    {props.sequences &&
                        props.sequences.map((sequence) => {
                            return (<Sequence
                                key={sequence._id}
                                sequence={sequence} />)
                        })
                    }
                    <div className={classes.Add}>
                        <Button onClick={addSequenceHandler}>+ Add new sequence</Button>
                    </div>
                </CardContent>
            </Card>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        sequences: state.sequenceBuilder.sequences,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setSequences: (sequences) => { dispatch({ type: actions.SET_SEQUENCES, sequences: sequences }) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(sequences);