import React, { Component } from 'react';
import {
    Avatar,
    TableCell,
    TableRow,
    Typography,

} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment';
import Switch from '@material-ui/core/Switch';
import { pauseUser } from '../../../../../api/api';
import { getInitials } from 'helpers';
import classes from './User.module.css';

class User extends Component {
    state = {
        paused: false,
        checked: false
    }
    componentDidMount() {
        this.setState({ paused: this.props.user.paused })
    }

    userCheckedHandler = (event) => {
        this.props.onUserChecked(event.target.checked, this.props.user._id);
        this.setState({ checked: event.target.checked });
    }

    userPausedHandler = (event) => {
        this.setState({ paused: event.target.checked });
        pauseUser(this.props.user.fb_id, event.target.checked, (response) => {
        }, (error) => {
            console.log(error);
        });
    }

    render() {
        return (
            <TableRow
                className={classes.tableRow}
                hover
                key={this.props.user._id}
                selected={this.state.checked}
            >
                <TableCell padding="checkbox">
                    <Checkbox
                        checked={this.state.checked}
                        color="primary"
                        onChange={this.userCheckedHandler}
                    />
                </TableCell>
                <TableCell>
                    <div className={classes.nameContainer}>
                        <Avatar
                            className='mr-4'
                            src={this.props.user.profile_pic}
                        >
                            {getInitials(this.props.user.first_name + ' ' + this.props.user.last_name)}
                        </Avatar>
                        <div>
                            <Typography variant="body1">{this.props.user.first_name + ' ' + this.props.user.last_name}</Typography>
                            <Typography variant="body2">{this.props.user.fb_id}</Typography>
                        </div>
                    </div>
                </TableCell>
                <TableCell>
                    <Switch
                        checked={this.state.paused}
                        onChange={this.userPausedHandler}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </TableCell>
                <TableCell>{this.props.user.bot_name}</TableCell>
                <TableCell>{this.props.user.message_count}</TableCell>
                <TableCell>{this.props.user.session_count}</TableCell>
                <TableCell>
                    <Typography variant="body1" >{this.props.user.last_message}</Typography>
                    <Typography variant="body2">{moment.unix(this.props.user.last_message_time / 1000).format('dddd, MMMM Do, YYYY h:mm:ss A')}</Typography>
                </TableCell>
            </TableRow>
        )
    }
}

export default User;