export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';


export const SET_QUERY = 'SET_QUERY';
export const SET_PAGE = 'SET_PAGE';
export const SET_USERS = 'SET_USERS';
export const SET_USERS_COUNT = 'SET_USERS_COUNT';
export const SET_IS_FILTERED = 'SET_IS_FILTERED';
export const SET_IS_USERS_LOADING = 'SET_IS_USERS_LOADING';


export const SET_SEQUENCES = 'SET_SEQUENCES';
export const SET_SEGMANTS = 'SET_SEGMANTS';
export const SET_MESSAGE = 'SET_MESSAGE';
export const SET_SELECTED_STEP = 'SET_SELECTED_STEP';
export const SET_SELECTED_SEQUENCE= 'SET_SELECTED_SEQUENCE';
export const SET_IS_LOADING_MESSAGE = 'SET_IS_LOADING_MESSAGE';
export const SET_CLICKED_STEP = 'SET_CLICKED_STEP';
export const SET_SELECTED_SEGMANT = 'SET_SELECTED_SEGMANT';
export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const HIDE_SNACKBAR = 'HIDE_SNACKBAR';