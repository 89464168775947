import * as actions from '../actions/actions';
const initialState = {
    token: null,
    userId: null,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.AUTH_SUCCESS:
            return {
                ...state,
                token: action.token,
                userId: action.userId,
            }
        case actions.AUTH_LOGOUT: {
            return {
                ...state,
                token: null,
                userId: null,
            }
        }
        default:
            return state
    }
}

export default reducer;