import React, { Component } from 'react';
import classes from './QueryRow.module.css';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { IconButton } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { createQuery, listSegments } from '../../../../../api/api';


class QueryRow extends Component {

    state = {
        value: null,
        segments: [],
        createQuery: [],
        currentQuery: {
            operations: [],
            values: []
        }
    }

    getColumnOptions = () => {
        let options = this.state.createQuery.map((option) => {
            return option.key;
        })
        return options;
    }

    getOperationOptions = () => {
        let options = []
        let currentQuery = this.state.createQuery.find((option) => {
            return option.key === this.props.row.column
        });
        if (currentQuery) options = currentQuery.operations;
        return options;

    }

    getQuerySearchFilter = (value) => {
        createQuery(value,
            (response) => {
                this.setState({ createQuery: response.data.data.createQuery });
            }, (error) => {
                console.log(error);
            })
    }

    getSegmentsList = () => {
        listSegments((response) => {
            let segments = response.data.data.listSegment.map((segment) => {
                return segment.name;
            });
            this.setState({ segments: segments });
        }, (error) => {
            console.log(error);
        })
    }

    onChangedHandler = (item, event, value) => {
        switch (item) {
            case 'group':
                this.setState({
                    value: null,
                    createQuery: []
                })
                if (value) {
                    if (value === 'segment') {
                        this.getSegmentsList();
                    }
                    else {
                        this.getQuerySearchFilter(value);
                    }
                }
                break;
            case 'column':
                this.setState({
                    value: null,
                    currentQuery: {
                        operations: [],
                        values: []
                    }
                })
                if (value) {
                    let currentQuery = this.state.createQuery.find((option) => {
                        return option.key === value
                    });
                    this.setState({ currentQuery: currentQuery })
                }
                break;
            default:
        }
        this.props.onChanged(item, value)
    }

    onValueSelectedHandler = (event, values) => {
        if (!this.props.row.value.includes(this.state.value)) {
            this.setState({ value: null });
            this.props.onChanged('value', values)
        }
    }

    getValuesOptions = () => {
        let options = [];
        if (this.state.value)
            options.push(this.state.value);
        return options;
    }

    onValueChangedHandler = (event) => {
        this.setState({ value: event.target.value });
    }

    onDateChangedHandler = (value) => {
        this.props.onChanged('value', [value]);
    }

    renderValueInput = () => {
        let input = null;
        let type = this.state.currentQuery.type;
        switch (type) {
            case 'Date':
                input = (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            autoOk
                            inputVariant="outlined"
                            label="Select date..."
                            format="MM/dd/yyyy"
                            value={this.props.row.value[0] ? this.props.row.value[0] : null}
                            onChange={this.onDateChangedHandler}
                        />
                    </MuiPickersUtilsProvider>
                )
                break;

            default:
                input = (
                    <Autocomplete
                        multiple
                        value={this.props.row.value}
                        onChange={this.onValueSelectedHandler}
                        options={this.props.row.group === 'segment' ? this.state.segments :
                            this.state.currentQuery.values ? this.state.currentQuery.values :
                                this.getValuesOptions()}
                        onInput={this.onValueChangedHandler}
                        getOptionLabel={option => option}
                        renderInput={params => (
                            <TextField {...params} type={type} variant="outlined" label="Values..." />
                        )}
                    />
                )
                break;
        }
        return input;
    }


    render() {
        return (
            <div className='row py-1'>
                <div className='col-2 px-0'>
                    <Autocomplete
                        options={['user_data', 'attributes', 'segment']}
                        getOptionLabel={option => option.replace(/^\w/, c => c.toUpperCase()).replace('_', ' ')}
                        onChange={this.onChangedHandler.bind(this, 'group')}
                        value={this.props.row.group}
                        renderInput={params => <TextField {...params} label="Select group..." variant="outlined" />}
                    />
                </div>
                {this.props.row.group !== 'segment' &&
                    <div className='col-2 px-1'>
                        <Autocomplete
                            value={this.props.row.column}
                            options={this.getColumnOptions()}
                            getOptionLabel={option => option}
                            onChange={this.onChangedHandler.bind(this, 'column')}
                            renderInput={params => <TextField {...params} label="Select option..." variant="outlined" />}
                        />
                    </div>
                }
                <div className='col-2 px-1'>
                    <Autocomplete
                        value={this.props.row.operation}
                        options={this.props.row.group === 'segment' ? ['is', 'is not'] : this.state.currentQuery.operations}
                        getOptionLabel={option => option}
                        onChange={this.onChangedHandler.bind(this, 'operation')}
                        renderInput={params => <TextField {...params} label="Select option..." variant="outlined" />}
                    />
                </div>
                <div className='col-2 px-1'>
                    {this.renderValueInput()}
                </div>

                {!this.props.isLastRow &&
                    <div className='col-2 px-1'>
                        <Autocomplete
                            value={this.props.row.sub_operation}
                            options={['and', 'or']}
                            getOptionLabel={option => option}
                            onChange={this.onChangedHandler.bind(this, 'sub_operation')}
                            renderInput={params => <TextField {...params} label="Select..." variant="outlined" />}
                        />
                    </div>}

                {this.props.canRemoved && <div>
                    <IconButton className={classes.signOutButton} onClick={this.props.onRemoved}>
                        <HighlightOffIcon color='error' />
                    </IconButton>
                </div>}

            </div >
        )
    }
}

export default QueryRow;