import graphql from './graphql';

export const listSegments = (success, failure) => {
    graphql.post('', {
        query: `{
            listSegment{
                _id
                name
                query
                raw_query
            }
        }`
    }).then((response) => {
        success(response);
    }).catch((error) => {
        failure(error);
    })
}

export const pauseUser = (id, paused, success, failure) => {
    graphql.post('', {
        query: `mutation{
            PauseUser(fb_id : "${id}", paused : ${paused}) 
    }`
    }).then((response) => {
        success(response);
    }).catch((error) => {
        failure(error);
    })
}

export const createQuery = (value, success, failure) => {
    graphql.post('', {
        query: `mutation{    
                        createQuery(type :"${value}" ){
                            key
                            type
                            operations
                            values
                        }
                    }`
    }).then((response) => {
        success(response)
    }).catch((error) => {
        failure(error);
    })
}

export const submitQueryBuilder = (page, query, success, failure) => {
    let queryData = JSON.stringify(query).replace(/"([^"]+)":/g, '$1:');
    graphql.post('', {
        query: `{submitQueryBuilder(page:${page},query:${queryData}){
          count
          currentPage
          pageNumbers
          people {
            _id
            fb_id
            first_name
            last_name
            paused
            name
            session_count
            message_count
            last_message
            last_message_time
            profile_pic
            bot_name
          }
              }
        }`
    }).then((response) => {
        success(response);
    }).catch((error) => {
        failure(error);
    });
}


export const updateSequence = (sequence, success, failure) => {

    sequence.sequence_steps = sequence.sequence_steps.map((step)=>{
        step.message = step.message.replace(/\\"/g,'"');
        step.message = step.message.replace(/\\:/g,':');
        step.message = step.message.replace(/"/g,'\\"');
        step.message = step.message.replace(/:/g,'\\:');
        return step;
    })    
    let steps = JSON.stringify(sequence.sequence_steps).replace(/"([^"]+)":/g, '$1:');
    graphql.post('', {
        query: `mutation{
            updateSequence(sequence:"${sequence._id}" , name:"${sequence.name}" , sequence_steps:${steps}
            ){
                name
                sequence_steps{
                    message
                }
            }
        }`
    }).then((response) => {
        if (response.data.data.updateSequence) {
            success(response);
        }
        else {
            failure(response);
        }
    }).catch((error) => {
        failure(error);
    });
}


export const login = (email, password, success, failure) => {
    graphql.post('', {
        query: `{
            login(email : "${email}", password : "${password}"){
            userId   
            token 
            expToken
          }
        }`
    }).then((response) => {
        success(response);
    }).catch((error) => {
        failure(error);
    });
}


export const assignSequence = (segmant, sequence, success, failure) => {
    graphql.post('', {
        query: `mutation{
            assignSequence(segment:"${segmant}" , sequence:"${sequence}")
        }`
    }).then((response) => {
        success(response)
    }).catch((error) => {
        failure(error);
    });
}


export const getPeople = (page, success, failure) => {
    graphql.post('', {
        query: `{
        people(page : ${page}){
          count
          hasNextPage
          limit
          currentPage
          pageNumbers
          people {
            _id
            fb_id
            first_name
            last_name
            paused
            name
            session_count
            message_count
            last_message
            last_message_time
            profile_pic
            bot_name
          }
        }
      } `
    }).then((response) => {
        success(response);
    }).catch((error) => {
        failure(error);
    })
}

export const createSegmant = (name, query) => {
    let queryData = JSON.stringify(query).replace(/"([^"]+)":/g, '$1:');
    graphql.post('', {
        query: `mutation{
        createSegment(name : "${name}", query : ${queryData}){
              name
              query
        }
    }`});
}

export const listSequences = (success, failure) => {
    graphql.post('', {
        query: `{
            listSequence{
                _id
                name
                paused
                sequence_steps{
                    message
                    time_interval
                    unit
                }
            }
        }`
    }).then((response) => {
        
        success(response);
    }).catch((error) => {
        failure(error);
    })
}


export const createSequence = (name, steps, success, failure) => {
    let stepsData = JSON.stringify(steps).replace(/"([^"]+)":/g, '$1:');
    graphql.post('', {
        query: `mutation{
            createSequence(name:"${name}" , sequence_steps:${stepsData})
        }`}).then((response) => { success(response) }).catch((error) => {
            failure(error)
        });
}

export const pauseSequence = (sequence, paused, success, failure) => {
    graphql.post('', {
        query: `mutation{pauseSequence(sequence:"${sequence}" pause:"${paused}")}   `
    }).then((response) => { success(response) }).catch((error) => {
        failure(error)
    });
}

export const deleteSequence = (sequence, success, failure) => {
    graphql.post('', {
        query: `mutation{deleteSequence(sequence:"${sequence}")} `
    }).then((response) => { success(response) }).catch((error) => {
        failure(error)
    });
}