import React, { useState } from 'react';
import { connect } from 'react-redux';
import classes from './MessageBlock.module.css';
import {
    Card,
    CardContent,
    Typography,
    Button,
} from '@material-ui/core';
import * as actions from '../../../../store/actions/actions';
import CircularProgress from '@material-ui/core/CircularProgress';
import { assignSequence } from '../../../../api/api';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const MessageBlock = props => {

    const [open, setOpen] = useState(false);


    let message = '';
    let sequenceName = '';
    if (props.selectedSequence) {
        let sequence = props.sequences.find((sequence) => {
            return sequence._id === props.selectedSequence;
        });
        sequenceName = sequence.name;
    }
    if (props.selectedStep) {
        let arr = props.selectedStep.split('_');
        let step_message = props.sequences.find((sequence) => {
            return sequence._id === arr[0];
        }).sequence_steps[arr[1]].message;
        message = step_message.replace(/\\"/g, '"').replace(/\\:/g, ':');
    }

    const messageChangedHandler = event => {
        let arr = props.selectedStep.split('_');
        let updatedSequences = [...props.sequences];
        if (props.selectedStep) {
            updatedSequences.find((sequence) => {
                return sequence._id === arr[0];
            }).sequence_steps[arr[1]].message = event.target.value;
        }
        props.setSequences(updatedSequences);
    }


    const segmantSelectedHandler = (event) => {
        props.setSelectedSegmant(event.target.value);
    }

    const sequenceNameChangedHandler = event => {
        let arr = props.selectedStep.split('_');
        let updatedSequences = [...props.sequences];
        if (props.selectedStep) {
            updatedSequences.find((sequence) => {
                return sequence._id === arr[0];
            }).name = event.target.value;
        }
        props.setSequences(updatedSequences);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const openDialogeHandler = () => {
        setOpen(true);
    }

    const assignSequenceHandler = () => {
        setOpen(false);
        assignSequence(props.selectedSegmant, props.selectedSequence, (response) => {
            props.showSnackbar('success', 'Segmant assign successfully');
        }, (error) => {
            props.showSnackbar('error', 'Segmant assigning failed');
        })
    }

    return (
        <Card className={classes.MessageBlock}>

            {props.selectedSequence && (
                <div className={classes.Title} >
                    <input value={sequenceName} className={classes.Sequence} onChange={sequenceNameChangedHandler} />
                    <select value={props.selectedSegmant} className={classes.Segmants} onChange={segmantSelectedHandler}>
                        <option value=''>None</option>
                        {props.segmants.map((segmant) => {
                            return (<option key={segmant.id} value={segmant.id}>{segmant.name}</option>)
                        })}
                    </select>
                    {props.selectedSegmant && <Button onClick={openDialogeHandler} color="primary">Assign</Button>}
                </div>)}

            {props.isLoadingMessage ?
                <CircularProgress className={classes.Loader} color="primary" size={30} /> :
                props.selectedStep && (
                    <CardContent className='h-100'>
                        <Typography className={classes.Step} variant="subtitle2">{`Step #${parseInt(props.selectedStep.split('_')[1]) + 1}`} </Typography>
                        <textarea
                            onChange={messageChangedHandler}
                            value={message}
                            className={classes.Message}
                        >
                        </textarea>
                    </CardContent>
                )
            }
            {props.selectedSegmant &&
                <Dialog
                    open={open}
                    onClose={handleClose}
                >

                    <DialogTitle >{"Assign sequence to segment?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText >
                            Are you sure to assign {sequenceName} to {props.segmants.find((segmant) => {
                            return segmant.id === props.selectedSegmant;
                        }).name}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
          </Button>
                        <Button onClick={assignSequenceHandler} color="primary" autoFocus>
                            Yes
          </Button>
                    </DialogActions>
                </Dialog>
            }
        </Card >
    )
}

const mapStateToProps = state => {
    return {
        sequences: state.sequenceBuilder.sequences,
        segmants: state.sequenceBuilder.segmants,
        selectedStep: state.sequenceBuilder.selectedStep,
        isLoadingMessage: state.sequenceBuilder.isLoadingMessage,
        selectedSegmant: state.sequenceBuilder.selectedSegmant,
        selectedSequence: state.sequenceBuilder.selectedSequence,
    }
}


const mapDispatchToProps = dispatch => {
    return {
        setSequences: (sequences) => { dispatch({ type: actions.SET_SEQUENCES, sequences: sequences }) },
        showSnackbar: (snackbar, message) => { dispatch({ type: actions.SHOW_SNACKBAR, snackbar: snackbar, message: message }) },
        setSelectedSegmant: (segmant) => { dispatch({ type: actions.SET_SELECTED_SEGMANT, segmant: segmant }) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageBlock);