import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination
} from '@material-ui/core';

import User from './User/User';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/actions';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  loader: {
    textAlign: "center",
    padding: theme.spacing(2)
  },
  inner: {
    minWidth: 1050
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const UsersTable = props => {
  const { className, users, usersCount, onPageChanged, onUserChecked, currentPage, setPage, isUsersLoading, dispatch, ...rest } = props;
  const classes = useStyles();
  // const [selectedUsers, setSelectedUsers] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(20);



  // const handleSelectAll = event => {
  //   const { users } = props;

  //   let selectedUsers;

  //   if (event.target.checked) {
  //     selectedUsers = users.map(user => user._id);
  //   } else {
  //     selectedUsers = [];
  //   }

  //   setSelectedUsers(selectedUsers);
  // };

  // const handleSelectOne = (event, id) => {
  //   const selectedIndex = selectedUsers.indexOf(id);
  //   let newSelectedUsers = [];

  //   if (selectedIndex === -1) {
  //     newSelectedUsers = newSelectedUsers.concat(selectedUsers, id);
  //   } else if (selectedIndex === 0) {
  //     newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(1));
  //   } else if (selectedIndex === selectedUsers.length - 1) {
  //     newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelectedUsers = newSelectedUsers.concat(
  //       selectedUsers.slice(0, selectedIndex),
  //       selectedUsers.slice(selectedIndex + 1)
  //     );
  //   }

  //   setSelectedUsers(newSelectedUsers);
  // };

  const handlePageChange = (event, page) => {
    setPage(page)
    onPageChanged(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardActions className={classes.actions}>
        {!isUsersLoading &&
          <TablePagination
            component="div"
            count={usersCount}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={currentPage}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10]}
          />}
      </CardActions>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    {/* <Checkbox
                      checked={selectedUsers.length === users.length && users.length !== 0}
                      color="primary"
                      // indeterminate={
                      //   selectedUsers.length > 0 &&
                      //   selectedUsers.length < users.length
                      // }
                      onChange={handleSelectAll}
                    /> */}
                  </TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Paused</TableCell>
                  <TableCell>Bot name</TableCell>
                  <TableCell>Messages Count</TableCell>
                  <TableCell>Sessions Count</TableCell>
                  <TableCell>Last Message</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.slice(0, rowsPerPage).map(user => {
                  return (<User user={user} onUserChecked={onUserChecked} key={user._id} />)
                }
                )}
              </TableBody>
            </Table>
            {
              !isUsersLoading ? users.length === 0 ?
                <div className={classes.loader}>No users</div> : null :
                <div className={classes.loader}>
                  <CircularProgress />
                </div>
            }
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

UsersTable.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired
};

const mapStateToProps = state => {
  return {
    currentPage: state.queryBuilder.currentPage,
    users: state.queryBuilder.users,
    usersCount: state.queryBuilder.usersCount,
    isUsersLoading: state.queryBuilder.isUsersLoading
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setPage: (page) => { dispatch({ type: actions.SET_PAGE, page: page }) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersTable);
