import React, { Component } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import * as actions from './store/actions/actions';
import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';
import { connect } from 'react-redux';
import {
  UserList as UserListView,
  SequenceBuilder as SequencesView,
  SignIn as SignInView,
  NotFound as NotFoundView
} from './views';

class Routes extends Component {

  componentDidMount() {
    const token = localStorage.getItem('token');
    if (!token) {
      this.props.logout();
    } else {
      const userId = localStorage.getItem('userId');
      this.props.autoSignIn(token, userId);
    }
  }


  render() {
    let routes = null;
    if (!localStorage.getItem('token') && !this.props.isAuthenticated ) {
      routes = (
        <Switch>
          <RouteWithLayout
            component={SignInView}
            exact
            layout={MinimalLayout}
            path="/sign-in"
          />
          <Redirect to="/sign-in" />
        </Switch>
      );
    }
    else {
      routes = (
        <Switch>
          <Redirect
            exact
            from="/"
            to="/users"
          />
          <Redirect
            exact
            from="/sign-in"
            to="/users"
          />
          <RouteWithLayout
            component={UserListView}
            exact
            layout={MainLayout}
            path="/users"
          />
          <RouteWithLayout
            component={SequencesView}
            exact
            layout={MainLayout}
            path="/sequences"
          />
          <RouteWithLayout
            component={NotFoundView}
            exact
            layout={MinimalLayout}
            path="/not-found"
          />
          <Redirect to="/not-found" />
        </Switch>
      )
    }

    return routes;
  }
};
const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    autoSignIn: (token, userId) => { dispatch({ type: actions.AUTH_SUCCESS, token: token, userId: userId }) },
    logout: () => { dispatch({ type: actions.AUTH_LOGOUT, }) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
