import React from 'react';
import Step from '../Step/Step'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/actions';
import SubscribersIcon from '@material-ui/icons/People';
import OpenIcon from '@material-ui/icons/Visibility';
import ClickIcon from '@material-ui/icons/TouchApp';
import AddIcon from '@material-ui/icons/Add';
import classes from './Sequence.module.css';
import Box from '@material-ui/core/Box';
import { Button } from '@material-ui/core';
import Menu from '../OptionsMenu/OptionsMenu';
import { updateSequence, pauseSequence, deleteSequence } from '../../../../api/api';

function Sequence(props) {
    const optionSelectedHandler = (option) => {
        switch (option) {
            case 'Pause':
            case 'Unpause':
                pauseSequence(props.sequence._id, option === 'Pause', (response) => {
                    props.showSnackbar('success', `Sequence ${option}d successfully`);
                    let updatedSequences = props.sequences.map((sequence) => {
                        if (sequence._id === props.sequence._id) {
                            sequence.paused = option === 'Pause' ? "true" : "false";
                        }
                        return sequence;
                    })
                    props.setSelectedStep(null);
                    props.setSequences(updatedSequences);
                }, (error) => {
                    props.showSnackbar('error', `Sequence ${option}d failed`);
                });
                break;

            case 'Delete':
                deleteSequence(props.sequence._id, (response) => {
                    props.showSnackbar('success', 'Sequence deleted successfully');
                    let updatedSequences = props.sequences.filter((sequence) => {
                        return sequence._id !== props.sequence._id;
                    })
                    props.setSelectedStep(null);
                    props.setSequences(updatedSequences);
                }, (error) => {
                    props.showSnackbar('error', 'Sequence delete failed');
                });

                break;

            default:
                break;
        }

    }


    const saveSequenceHandler = () => {
        updateSequence(props.sequence, (response) => {
            props.showSnackbar('success', 'Sequence updated successfully')
        }, (error) => {
            props.showSnackbar('error', 'Sequence update failed');
        });
    }
    const newStepHandler = (id) => {
        const step = {
            unit: 'immediately',
            time_interval: '0',
            message: ''
        };
        let updatedSequences = props.sequences.map((sequence) => {
            if (sequence._id === id) {
                sequence.sequence_steps.push(step);
            }
            return sequence;
        });
        props.setClickedStep(null)
        props.setSequences(updatedSequences)
    }

    return (
        <div>
            <ExpansionPanel>
                <div className='d-flex'>
                    <ExpansionPanelSummary
                        className='w-100'
                        onClick={()=>{props.setSelectedSequence(props.sequence._id); props.setSelectedStep(null)}}
                        expandIcon={<ExpandMoreIcon />}
                    >

                        <div className={classes.Header}>
                            <div className={classes.Name}>
                                <Typography>{props.sequence.name}</Typography>
                            </div>
                            <div className={classes.Analytics}>
                                <SubscribersIcon fontSize='small' />
                                <span>0</span>
                                <OpenIcon fontSize='small' />
                                <span>0</span>
                                <ClickIcon fontSize='small' />
                                <span>0</span>
                            </div>
                        </div>

                    </ExpansionPanelSummary>
                    <Menu options={[props.sequence.paused === 'true' ? 'Unpause' : 'Pause', 'Delete']} onSelect={optionSelectedHandler} />
                </div>
                <ExpansionPanelDetails style={{ display: 'block', position: 'relative' }} >
                    <div className={classes.Outside} onClick={props.setClickedStep.bind(this, null)}></div>
                    {
                        props.sequence.sequence_steps.map((step, index) => {
                            let id = props.sequence._id + '_' + index;
                            return (<Step
                                id={id}
                                key={index}
                                step={step}
                                clicked={props.clickedStep === id}
                                selected={props.selectedStep === id} />)
                        })
                    }
                    <div className={classes.Options}>
                        <div >
                            <Box
                                boxShadow={1}
                                className={classes.Add}
                                onClick={newStepHandler.bind(this, props.sequence._id)}
                            >
                                <AddIcon />
                            </Box>
                        </div>
                        <Button onClick={saveSequenceHandler} color="primary">Save</Button>}
                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    )
}



const mapStateToProps = (state) => {
    return {
        sequences: state.sequenceBuilder.sequences,
        clickedStep: state.sequenceBuilder.clickedStep,
        selectedStep: state.sequenceBuilder.selectedStep,
        clickedSequence: state.sequenceBuilder.clickedSequence,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setSequences: (sequences) => { dispatch({ type: actions.SET_SEQUENCES, sequences: sequences }) },
        setLoadingMessage: (isLoading) => { dispatch({ type: actions.SET_IS_LOADING_MESSAGE, isLoading: isLoading }) },
        setClickedStep: (step) => { dispatch({ type: actions.SET_CLICKED_STEP, step: step }) },
        setSelectedStep: (step) => { dispatch({ type: actions.SET_SELECTED_STEP, step: step }) },
        setSelectedSequence: (sequence) => { dispatch({ type: actions.SET_SELECTED_SEQUENCE, sequence: sequence }) },
        showSnackbar: (snackbar, message) => { dispatch({ type: actions.SHOW_SNACKBAR, snackbar: snackbar, message: message }) },

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sequence);