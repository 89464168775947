import * as actions from '../actions/actions';
const initialState = {
    query: [
        {
            id: Date.now(),
            group: null,
            column: null,
            operation: null,
            value: [],
            sub_operation: 'or',
        },
    ],
    currentPage: 0,
    users: [],
    usersCount: 0,
    isFiltered: false,
    isUsersLoading: true
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_QUERY:
            return {
                ...state,
                query: action.query,
            }
        case actions.SET_PAGE:
            return {
                ...state,
                currentPage: action.page
            }
        case actions.SET_USERS:
            return {
                ...state,
                users: action.users,
            }
        case actions.SET_USERS_COUNT:
            return {
                ...state,
                usersCount: action.count
            }
        case actions.SET_IS_FILTERED:
            return {
                ...state,
                isFiltered: action.isFiltered
            }
        case actions.SET_IS_USERS_LOADING:
            return {
                ...state,
                isUsersLoading: action.isLoading
            }
        default:
            return state
    }
}

export default reducer;