import * as actions from '../actions/actions';
const initialState = {
    sequences: [],
    segmants: [],
    selectedStep: null,
    selectedSequence: null,
    isLoadingMessage: true,
    clickedStep: null,
    selectedSegmant: '',
    snackbar: {
        type: null,
        isOpen: false,
        message: ''
    }
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_SEQUENCES:
            return {
                ...state,
                sequences: action.sequences,
            }
        case actions.SET_SEGMANTS:
            return {
                ...state,
                segmants: action.segmants,
            }
        case actions.SET_MESSAGE:
            return {
                ...state,
                message: action.message,
                messageTitle: action.message.title,
            }
        case actions.SET_SELECTED_STEP:
            return {
                ...state,
                selectedStep: action.step,
                clickedStep: null
            }
        case actions.SET_SELECTED_SEQUENCE:
            console.log(action);
            
            return {
                ...state,
                selectedSequence: action.sequence,
                clickedStep: null
            }
        case actions.SET_IS_LOADING_MESSAGE:
            return {
                ...state,
                isLoadingMessage: action.isLoading
            }
        case actions.SET_CLICKED_STEP:
            return {
                ...state,
                clickedStep: action.step
            }
        case actions.SET_SELECTED_SEGMANT:
            return {
                ...state,
                selectedSegmant: action.segmant
            }
        case actions.SHOW_SNACKBAR:
            return {
                ...state,
                snackbar: {
                    message: action.message,
                    isOpen: true,
                    type: action.snackbar,
                }
            }
        case actions.HIDE_SNACKBAR:
            return {
                ...state,
                snackbar: {
                    ...state.snackbar,
                    isOpen: false,
                }
            }
        default:
            return state
    }
}

export default reducer;