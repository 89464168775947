import React, { Component } from 'react';
import { listSegments, listSequences } from '../../api/api';
import * as actions from '../../store/actions/actions';
import { connect } from 'react-redux';
import MessageBlock from './components/MessageBlock/MessageBlock';
import Sequences from './components/Sequences/Sequences'
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

class SequenceBuilder extends Component {
    componentDidMount() {
        listSegments((response) => {
            let segmants = response.data.data.listSegment.map((segmant) => {
                return { id: segmant._id, name: segmant.name };
            });
            this.props.setSegmants(segmants);
        }, (error) => {
            console.log(error);
        })
        this.listSequences();
    }

    listSequences = () => {
        listSequences((response) => {
            let sequences = response.data.data.listSequence;
            this.props.setSequences(sequences);
            this.props.setLoadingMessage(false);
        }, (error) => {
            console.log(error);
        })
    }


    render() {
        return (
            <React.Fragment>
                <div className='container-fluid p-3 h-100 overflow-hidden' >
                    <div className='row h-100'>
                        <div className='col-4 px-1'>
                            <Sequences />
                        </div>
                        <div className='col-8 px-1'>
                            <MessageBlock />
                        </div>
                    </div>
                </div>
                <Snackbar open={this.props.snackbar.isOpen} autoHideDuration={6000} onClose={this.props.hideSnackbar}>
                    <Alert onClose={this.props.hideSnackbar} severity={this.props.snackbar.type}>
                        {this.props.snackbar.message}
                    </Alert>
                </Snackbar>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        snackbar: state.sequenceBuilder.snackbar
    }
}


const mapDispatchToProps = dispatch => {
    return {
        setSequences: (sequences) => { dispatch({ type: actions.SET_SEQUENCES, sequences: sequences }) },
        setSegmants: (segmants) => { dispatch({ type: actions.SET_SEGMANTS, segmants: segmants }) },
        setLoadingMessage: (isLoading) => { dispatch({ type: actions.SET_IS_LOADING_MESSAGE, isLoading: isLoading }) },
        hideSnackbar: () => { dispatch({ type: actions.HIDE_SNACKBAR }) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SequenceBuilder);  