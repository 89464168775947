import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_GRAPHQL_DOMAIN
});

instance.defaults.headers.post['Content-Type'] = 'application/json';
instance.defaults.headers.post['api_key'] = 'aazoRZUpFd';
instance.defaults.headers.post['secret_key'] = '9wNSmG9rwz45CJXFHJblJYfsYNzfRbnY'



export default instance;